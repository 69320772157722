import { Box, Button, Modal, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import { useTranslation } from 'react-i18next'
import styles from './InviteFriendInfoModal.module.css'
import { ArrowBackOutlined, CloseRounded } from '@mui/icons-material'
import { useEffect, useState } from 'react'
import supabaseClient from '@/lib/supabaseClient'
import { currency } from '@/lib/currency'
import { definitions } from '@/interfaces/supabase'
import { getUserWalletInfo } from '@/lib/supabaseApi'
import { ORDER_STATUS_ID_CANCELLED, ORDER_STATUS_ID_PENDING_PAYMENT } from '@/lib/constants'

type InviteFriendInfoModalProps = {
  open: boolean
  setOpen: Function
  userId: number
}

type BenefitsData = {
  referred: boolean,
  name: string
  status: string
  benefit_amount: number
  created_at: string
  referrer_expired_at?: string | Date | undefined
  expired: boolean
}

export default function InviteFriendInfoModal({ userId, open, setOpen }: InviteFriendInfoModalProps) {
  const { t } = useTranslation()
  const [benefits, setBenefits] = useState<BenefitsData[]>([])
  const [wb, setWB] = useState(0)
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '98%',
    maxWidth: 750,
    bgcolor: 'background.paper',
    boxShadow: 24,
    pl: 4,
    pb: 4,
    pr: 4,
    borderRadius: '1.25rem'
  }

  const dateNow = new Date(Date.now())

  useEffect(() => {
    (async () => {
      if (!userId) return

      const response = await fetch(`/api/actions/history/${userId}`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
      })

      if (!response.ok) {
        return
      }

      const { referralBenefits, referrerBenefits, orders, walletBalance } = await response.json()

      const benefitsData: BenefitsData[] = []
      referralBenefits.forEach((rb: { referrer_user_id: { id: number; name: any }; referred_user_id: { name: any }; status: any; benefit_amount: any; created_at: any; referred_expired_at: any; referred_expired: any }) => {
        benefitsData.push({
          referred: true,
          name: (rb.referrer_user_id.id !== 0) ? rb.referred_user_id.name || '' : rb.referrer_user_id.name || '',
          status: t(`pages.invite_friend.info.${rb.status}`),
          benefit_amount: rb.benefit_amount,
          created_at: rb.created_at,
          referrer_expired_at: rb.referred_expired_at || '',
          expired: rb.referred_expired || false
        })
      })
      referrerBenefits.forEach((rb: { referred_user_id: { name: any }; status: any; benefit_amount: any; created_at: any; referrer_expired_at: any; referrer_expired: any }) => {
        benefitsData.push({
          referred: false,
          name: rb.referred_user_id.name || '',
          status: t(`pages.invite_friend.info.${rb.status}`),
          benefit_amount: rb.benefit_amount,
          created_at: rb.created_at,
          referrer_expired_at: rb.referrer_expired_at || '',
          expired: rb.referrer_expired || false
        })
      })
      orders.forEach((order: { id: any; wallet_amount_used: any; created_at: any }) => {
        benefitsData.push({
          referred: true,
          name: `Pedido ${order.id}` || '',
          status: t('pages.invite_friend.info.used_in_order'),
          benefit_amount: order.wallet_amount_used,
          created_at: order.created_at,
          expired: false
        })
      })

      setBenefits(benefitsData.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime()))
      setWB(walletBalance?.wallet_balance || 0)
    })()
  }, [open])

  const handleCloseModal = () => {
    setOpen(false)
  }

  const getRowColor = (status: string) => {
    if (status === 'pendiente') {
      return '#b7a936'
    } else if (status === 'obtenido') {
      return '#41e121'
    } else if (status === 'canjeado') {
      return '#d32f2f'
    } else if (status === 'modificado') {
      return '#7f7f7f'
    }
  }

  return (
    <div>
      <Modal
        open={open}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className={styles.modal}
      >
        <Box sx={style}>
          <h3>{t('pages.invite_friend.info.title')}</h3>
          <b>{t('pages.invite_friend.info.wallet_balance', { value: wb })}</b>
          <Button className={styles.close_button} onClick={handleCloseModal}>
            <CloseRounded />
          </Button>
          <div style={{ overflow: 'auto', height: '50vh', marginLeft: '-1rem' }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{t('pages.invite_friend.info.name')}</TableCell>
                  <TableCell align="center">{t('pages.invite_friend.info.status')}</TableCell>
                  <TableCell align="center">{t('pages.invite_friend.info.benefit_amount')}</TableCell>
                  {/* <TableCell align="center">{t('pages.invite_friend.info.created_at')}</TableCell> */}
                  <TableCell align="center">{t('pages.invite_friend.info.expired_at')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {benefits.map((data, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell className={styles.table_cell_bold}>
                        {data.name}
                      </TableCell>
                      <TableCell style={{
                          color: `${getRowColor((data.referrer_expired_at && (new Date(data.referrer_expired_at).toISOString()) < dateNow.toISOString()) ? t('pages.invite_friend.info.expired') : data.status.toLowerCase())}`
                        }}
                        className={styles.table_cell_bold}
                        align="center"
                      >
                        {(data.expired)
                          ? t('pages.invite_friend.info.expired')
                          : (index === benefits.length-1 && data.referred) ? t('pages.invite_friend.info.accepted') : data.status}
                      </TableCell>
                      <TableCell className={styles.table_cell} align="center">
                        {currency(data.benefit_amount)}
                      </TableCell>
                      {/* <TableCell className={styles.table_cell} align="center">
                        {new Date(data.created_at).toLocaleDateString()}
                      </TableCell> */}
                      {(data.referrer_expired_at) ? <TableCell className={styles.table_cell} align="center">
                        {new Date(data.referrer_expired_at).toLocaleString().slice(0, -3)}
                      </TableCell> : <TableCell className={styles.table_cell} align="center"></TableCell>}
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </div>
        </Box>
      </Modal>
    </div>
  )
}
